<template>
  <div class="onboarding-page__wrapper">
    <div class="review-page">
      <h2 class="title">함께 하지 못해 아쉬워요 :(</h2>
      <p class="sub-text-s2 text-gray-second">
        빅크리에이터에 관심 가지고 신청해주셔서 감사합니다.
        <br /><br />
        심사팀에서 신청서를 빠짐없이 고심하여 검토한 결과, 아쉽게도 작성해주신
        내용으로는 빅크리에이터로 함께 하기 어려울 것 같습니다.
        <br /><br />
        빅크가 크리에이터와 팬들이 즐겁게 활동할 수 있는 플랫폼이 되고자
        최소한의 검증 과정을 두는 점, 양해 부탁드립니다.
        <br /><br />
        빅크리에이터 신청은 언제든지 열려있으니, 아래 기준을 참고하셔서 재신청
        부탁드립니다. 다시 만나 뵐 수 있기를 기대합니다. 감사합니다.
      </p>

      <card-basic class="guide-card">
        <p class="title sub-text-s2">
          빅크 스튜디오 사용에 있어 최소한의 기준은 아래와 같습니다
        </p>
        <ul class="b-text-2 text-gray-second">
          <li>
            등록하신 SNS 또는 커뮤니티 및 본인 페이지 등에 창작자 본인 고유의
            콘텐츠로 활동하는지 여부
          </li>
          <li>
            기존 활동 중이신 채널에서의 실제 본인의 활동 등 계정의 활성화 여부
          </li>
          <li>
            기존 활동 내역에서 콘텐츠 위배사항 발견 시 제외(스캠, 어뷰징, 성적
            이슈 계정 차단)
          </li>
        </ul>

        <button-basic
          class="policy-btn"
          text="빅크 콘텐츠 운영 정책 자세히 보기"
          text-size="s3"
          bg-color="#ffffff"
          color="#0D0D10"
          padding="8px 0"
          border-color="#E6EAEF"
          :border="true"
          @action="actions.goToPolicy()"
        ></button-basic>
      </card-basic>

      <button-basic
        class="re-apply__btn"
        text="재신청 하기"
        @action="actions.goToInitStep()"
      ></button-basic>
    </div>
  </div>
</template>

<script>
import ButtonBasic from "../../../../components/console/buttons/ButtonBasic";
import CardBasic from "../../../../components/console/cards/CardBasic";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import helper from "@/helper";
import onBoardingConst from "../../../../consts/onboarding";

export default {
  name: "OnBoardingReview",
  components: { CardBasic, ButtonBasic },
  setup() {
    const store = useStore();
    const router = useRouter();

    const actions = {
      goToInitStep: () => {
        store
          .dispatch("onBoardings/putOnBoardingStep", {
            step: onBoardingConst.onBoardingSteps.start,
          })
          .then(() => {
            router.push({ name: "console.onboarding.profile" });
          });
      },
      goToPolicy: () => {
        helper.goToExternalUrl(
          "https://bigc-im.notion.site/2fd1b01e888c4bcab5d4f133a026da83"
        );
      },
    };

    return { actions };
  },
};
</script>

<style src="./style.css" scoped></style>
